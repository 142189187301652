.display-flex {
  display: flex;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.horizontal-align {
  display: flex;
  justify-content: center;
}

.full-center-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -ms-justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
  -webkit-justify-content: space-around;
  -ms-justify-content: space-around;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
  -ms-justify-content: space-evenly;
}

.justify-content-start {
  justify-content: start;
  -webkit-justify-content: flex-start;
  -ms-justify-content: flex-start;
}

.justify-content-end {
  justify-content: end;
  -webkit-justify-content: flex-end;
  -ms-justify-content: flex-end;
}

.justify-content-left {
  justify-content: left;
  -webkit-justify-content: flex-start;
  -ms-justify-content: flex-start;
}

.justify-content-right {
  justify-content: right;
  -webkit-justify-content: flex-end;
  -ms-justify-content: flex-end;
}

.justify-items-stretch {
  justify-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
}

.justify-items-center {
  justify-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.justify-items-start {
  justify-items: start;
  -webkit-box-align: start;
  -ms-flex-align: start;
}

.justify-items-end {
  justify-items: end;
  -webkit-box-align: end;
  -ms-flex-align: end;
}

.justify-items-left {
  justify-items: left;
  -webkit-box-align: start;
  -ms-flex-align: start;
}

.justify-items-right {
  justify-items: right;
  -webkit-box-align: end;
  -ms-flex-align: end;
}

.flex1 { flex: 1; }
.flex2 { flex: 2; }
.flex3 { flex: 3; }

/*====== Flex Flows ======*/
/*=== Row ===*/
.flex-row { flex-direction: row; }
.flex-row-wrap { flex-flow: row wrap; }

/*=== Column ===*/
.flex-column { flex-direction: column; }
.flex-column-wrap { flex-flow: column wrap; }