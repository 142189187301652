@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Changa:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kolker+Brush&display=swap');

@font-face {
  font-family: 'Bornneo';
  src: url("../fonts/Bornneo.woff2") format("woff2"),
       url("../fonts/Bornneo.woff") format("woff");
}

:root {

  --beige-0: #FFF8E7;
  --beige: #F9EFDB;
  --beige-mid: #ECE7CA;
  --beige-dark: #DACAA4;
  --beige-darker: #D1BB91;
  --beige-darkest: #C8AD7E;

  --gray: #E4E4E4;
  --gray-mid: #C3C3C1;
  --gray-dark: #A4A4A6;
  --gray-darker: #737373;
  --gray-darkest: #3D3D3D;

  --main-green-0: #A7E4BC;
  --main-green-1: #87D06C;
  --main-green-2: #BDFF3F;
  --main-green-3: #4fc879;
  --main-green-4: #A7E4BC; /* currently editing */
  --main-green-5: #4fc879;
  --main-green-6: #3aab58;
  --main-green-7: #1b842c;
  --main-green-8: #1A4C39;
  --main-green-9: #0F2C1F;

  --faded-green: rgba(27, 132, 44, 0.2);

  --font-xs: 14px;
  --font-s: 16px;
  --font-m: 18px;
  --font-l: 20px;
  --font-xl: 22px;
  --font-heading4: 24px;
  --font-heading3: 28px;
  --font-heading2: 32px;
  --font-heading1: 36px;

  --size-2xs: 2px;
  --size-xs: 4px;
  --size-s: 8px;
  --size-m: 12px;
  --size-l: 20px;
  --size-xl: 32px;

  --app-border-radius: 32px;
  --view-width-padding: 720px;
}

html {
  height: 100vh;
}

html, body, p {
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  height: 100%;
  background-color: var(--main-green-9);
  color: var(--main-green-4);
  font-size: var(--font-m);
  font-family: 'Changa', sans-serif;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

p {
  line-height: 1;
}

.ph:empty {
  display: none;
}

#root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/*====== Margins ======*/
/*=== All Sides ===*/
.margin-2xs { margin: var(--size-2xs); }
.margin-xs { margin: var(--size-xs); }
.margin-s { margin: var(--size-s); }
.margin-m { margin: var(--size-m); }
.margin-l { margin: var(--size-l); }
.margin-xl { margin: var(--size-xl); }

/*=== Right ===*/
.margin-right-2xs { margin-right: var(--size-2xs); }
.margin-right-xs { margin-right: var(--size-xs); }
.margin-right-s { margin-right: var(--size-s); }
.margin-right-m { margin-right: var(--size-m); }
.margin-right-l { margin-right: var(--size-l); }
.margin-right-xl { margin-right: var(--size-xl); }

/*=== Left ===*/
.margin-left-2xs { margin-left: var(--size-2xs); }
.margin-left-xs { margin-left: var(--size-xs); }
.margin-left-s { margin-left: var(--size-s); }
.margin-left-m { margin-left: var(--size-m); }
.margin-left-l { margin-left: var(--size-l); }
.margin-left-xl { margin-left: var(--size-xl); }

/*=== Top ===*/
.margin-top-2xs { margin-top: var(--size-2xs); }
.margin-top-xs { margin-top: var(--size-xs); }
.margin-top-s { margin-top: var(--size-s); }
.margin-top-m { margin-top: var(--size-m); }
.margin-top-l { margin-top: var(--size-l); }
.margin-top-xl { margin-top: var(--size-xl); }

/*=== Bottom ===*/
.margin-bottom-2xs { margin-bottom: var(--size-2xs); }
.margin-bottom-xs { margin-bottom: var(--size-xs); }
.margin-bottom-s { margin-bottom: var(--size-s); }
.margin-bottom-m { margin-bottom: var(--size-m); }
.margin-bottom-l { margin-bottom: var(--size-l); }
.margin-bottom-xl { margin-bottom: var(--size-xl); }

/*====== Paddings ======*/
/*=== All Sides ===*/
.padding-2xs { padding: var(--size-2xs); }
.padding-xs { padding: var(--size-xs); }
.padding-s { padding: var(--size-s); }
.padding-m { padding: var(--size-m); }
.padding-l { padding: var(--size-l); }
.padding-xl { padding: var(--size-xl); }

/*=== Right ===*/
.padding-right-2xs { padding-right: var(--size-2xs); }
.padding-right-xs { padding-right: var(--size-xs); }
.padding-right-s { padding-right: var(--size-s); }
.padding-right-m { padding-right: var(--size-m); }
.padding-right-l { padding-right: var(--size-l); }
.padding-right-xl { padding-right: var(--size-xl); }

/*=== Left ===*/
.padding-left-2xs { padding-left: var(--size-2xs); }
.padding-left-xs { padding-left: var(--size-xs); }
.padding-left-s { padding-left: var(--size-s); }
.padding-left-m { padding-left: var(--size-m); }
.padding-left-l { padding-left: var(--size-l); }
.padding-left-xl { padding-left: var(--size-xl); }

/*=== Top ===*/
.padding-top-2xs { padding-top: var(--size-2xs); }
.padding-top-xs { padding-top: var(--size-xs); }
.padding-top-s { padding-top: var(--size-s); }
.padding-top-m { padding-top: var(--size-m); }
.padding-top-l { padding-top: var(--size-l); }
.padding-top-xl { padding-top: var(--size-xl); }

/*=== Bottom ===*/
.padding-bottom-2xs { padding-bottom: var(--size-2xs); }
.padding-bottom-xs { padding-bottom: var(--size-xs); }
.padding-bottom-s { padding-bottom: var(--size-s); }
.padding-bottom-m { padding-bottom: var(--size-m); }
.padding-bottom-l { padding-bottom: var(--size-l); }
.padding-bottom-xl { padding-bottom: var(--size-xl); }

/*====== Font Sizes ======*/
.font-size-xs { font-size: var(--font-xs); }
.font-size-s { font-size: var(--font-s); }
.font-size-m { font-size: var(--font-m); }
.font-size-l { font-size: var(--font-l); }
.font-size-xl { font-size: var(--font-xl); }
.font-size-heading4 { font-size: var(--font-heading4); }
.font-size-heading3 { font-size: var(--font-heading3); }
.font-size-heading2 { font-size: var(--font-heading2); }
.font-size-heading1 { font-size: var(--font-heading1); }

/*====== Gaps ======*/
/*=== Row Gap ===*/
.row-gap-2xs { row-gap: var(--size-2xs); }
.row-gap-xs { row-gap: var(--size-xs); }
.row-gap-s { row-gap: var(--size-s); }
.row-gap-m { row-gap: var(--size-m); }
.row-gap-l { row-gap: var(--size-l); }
.row-gap-xl { row-gap: var(--size-xl); }

/*=== Column Gap ===*/
.column-gap-2xs { column-gap: var(--size-2xs); }
.column-gap-xs { column-gap: var(--size-xs); }
.column-gap-s { column-gap: var(--size-s); }
.column-gap-m { column-gap: var(--size-m); }
.column-gap-l { column-gap: var(--size-l); }
.column-gap-xl { column-gap: var(--size-xl); }

/*=== Full sizings===*/

.full-width { width: 100%;}
.full-height { height: 100%}

/*====== Display rules ======*/

#header {
  position: fixed;
  top: var(--size-m);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 1.2em;
  letter-spacing: 1px;
  width: calc(100vw - var(--view-width-padding));
}

#header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

#the-title {
  position: absolute;
  left: var(--size-xl);
  font-size: 1.1em;
}

#main-content {
  width: 100%;
  max-width: calc(100vw - var(--view-width-padding));
  margin: 122px auto 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

#announcement-layout {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  line-height: 1;
  justify-content: center;
  text-shadow: var(--main-green-1) 1px 1px 10px;
}

#content-title-bar {
  display: flex;
  margin-bottom: var(--size-l);
}

#content-title-bar > div {
  flex: 1;
}

#page-content {
  overflow-y: auto;
  flex: 1;

}

.content-actions {
  display: flex;
  align-items: center;
  justify-content: end;
}

.content-container {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.content-title {
  font-size: 2em;
  font-weight: bold;
  line-height: 1;
}

.content-subtitle {
  margin-top: var(--size-xs)
}

.page-title-tabs {
  display: flex;
  background-color: var(--main-green-9);
  color: var(--main-green-0);
  border: solid var(--size-xs) var(--main-green-1);
  border-radius: var(--app-border-radius);
  overflow: hidden;
  padding: var(--size-m) var(--size-l);
}

.page-title-tab {
  position: relative;
  cursor: pointer;
  padding: 0 var(--size-m);
  text-align: center;
  font-weight: bold;
}

.page-title-tab::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  height: var(--size-2xs);
  width: 0;
  background-color: var(--main-green-0);
  transition: width 0.2s ease-out;
}

.page-title-tab.active::after {
  width: calc(100% - 2 * var(--size-s));
}

.back-chevron {
  position: relative;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.back-chevron::before,
.back-chevron::after {
  content: '';
  position: absolute;
  left: 25%;
  height: 40%;
  width: var(--size-2xs);
  background-color: var(--main-green-0);
}

.back-chevron::before {
  top: 25%;
  transform: translate(-25%, calc(-25% + 0.5px));
  rotate: 45deg;
}

.back-chevron::after {
  bottom: 25%;
  transform: translate(-25%, calc(25% - 0.5px));
  rotate: -45deg;
}

/*====== Retro Console ======*/

.retro-console {
  background-color: var(--main-green-9);
  padding: var(--size-xs);
  border: solid var(--main-green-1) 4px;;
  border-radius: var(--app-border-radius);
  box-shadow: inset 0 0 12px var(--main-green-1);
  letter-spacing: 1px;
  font-size: 0.8em;
  color: var(--main-green-0);
}

#header .retro-console {
  font-size: 0.9em;
}

.retro-console table {
  width: 100%;
  max-width: 100%;
  padding: 0 var(--size-xs);
}

.retro-console tr{
  display: flex;
  align-items: center;
}

.retro-console th {
  font-size: 1.2em;
}

.retro-console th:not(:last-of-type),
.retro-console td:not(:last-of-type),
.retro-console tbody tr {
  position: relative;
}

.retro-console td:not(:last-of-type)::before {
  content: '';
  position: absolute;
  right: 0;
  height: 100%;
  border-right: dashed var(--size-2xs) var(--main-green-4);
}

.retro-console tr:last-of-type td:not(:last-of-type)::before {
  top: 0;
  height: calc(100% - var(--size-s));
}

.retro-console thead th::before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  height: calc(100% - var(--size-s));
  border-right: dashed var(--size-2xs) var(--main-green-4);
}

.retro-console tbody tr::before {
  content: '';
  position: absolute;
  top: calc(-1 * var(--size-2xs) / 2);
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2*var(--size-m));
  border-top: dashed var(--size-2xs) var(--main-green-4);
}

.retro-console th,
.retro-console td{
  padding: var(--size-s) var(--size-m);
  white-space: nowrap;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.retro-console th.main-col,
.retro-console td.main-col{
  justify-content: left;
  flex: 1 1 26%;
}

/*====== Interaction Card ======*/

.interaction-card {
  padding: var(--size-s) var(--size-l);
}

.interaction-card-title {
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--size-2xs);
  cursor: pointer;
}

.interaction-card-icon {
  position: relative;
  height: 24px;
  width: 24px;
}

.interaction-card-subsection {
  position: relative;
  padding: var(--size-xs) 0;
}

.interaction-card-subsection::before {
  content: '';
  position: absolute;
  top: calc(-1 * var(--size-2xs) / 2);
  width: 100%;
  border-top: dashed var(--size-2xs) var(--main-green-4);
}

.interaction-card-subtitle {
  font-size: 1.05em;
  font-weight: bold;
}

.interaction-card-description {
  white-space: pre-wrap;
}

.interaction-card-icon::after,
.interaction-card-icon::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--main-green-4);
  transition: transform 0.2s ease-in;
}

.interaction-card-icon::after {
  height: 60%;
  width: 2px;
}

.interaction-card-icon::before {
  height: 2px;
  width: 60%;
}

.interaction-card.is-open .interaction-card-title {
  margin-bottom: var(--size-s);
}

.interaction-card.is-open .interaction-card-icon::after {
  transform: translate(-50%, -50%) rotate(-90deg);
}

/*====== Game Card ======*/

.game-card {
  position: relative;
  background-color: var(--main-green-9);
  color: var(--main-green-4);
  font-size: 1.2em;
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
  border-right: solid var(--size-s) var(--main-green-1);
  border-bottom: solid var(--size-s) var(--main-green-1);
  border-radius: var(--app-border-radius);
  padding: var(--size-l) var(--size-xl);
  flex: 1 1 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.25s;
}

/*====== Announcement Card ======*/

.announcement-card {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: var(--size-xl);
}

.announcement-card__title {
  position: relative;
  font-family: "Bornneo", sans-serif;
  font-size: 120px;
  text-align: center;
  margin: 60px 90px;
  padding: var(--size-xl);
  max-width: 600px;
}

.announcement-card__title::before,
.announcement-card__title::after {
  position: absolute;
  content: '';
  height: 35%;
  width: 45%;
}

.announcement-card__title::before {
  left: 0;
  top: 0;
  border-left: dashed var(--size-xs) var(--main-green-1);
  border-top: dashed var(--size-xs) var(--main-green-1);
}

.announcement-card__title::after {
  right: 0;
  bottom: 0;
  border-right: dashed var(--size-xs) var(--main-green-1);
  border-bottom: dashed var(--size-xs) var(--main-green-1);
}

.announcement-card__description {
  font-size: var(--font-xl);
  width: 100%;
}

.announcement-card__time {
  font-size: 80px;
  text-align: center;
  letter-spacing: var(--size-2xs);
  margin-bottom: 120px;
}

.announcement-card__moment {
  font-size: var(--font-heading3);
  width: 100%;
  margin-bottom: var(--size-m);
}

/*====== Device Breakpoints ======*/

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  :root {
    --big-border: solid var(--beige-dark) 12px;
    --view-width-padding: calc(2 * var(--size-s));
  }

  body {
    font-size: var(--font-xs);
  }

  #main-content {
    margin-top: 90px;
  }

  #header-content {
    justify-content: flex-end;
    margin-right: var(--size-l);
  }

  #content-title-bar {
    flex-direction: column;
    margin-bottom: var(--size-m);
  }

  .page-title-tabs,
  .page-title-tab {
    flex: 1;
  }

  .content-title,
  .content-subtitle {
    padding: 0 var(--size-xs);
  }

  .content-actions {
    justify-content: center;
    margin-top: var(--size-xs);
  }

  .content-subtitle,
  .page-title-tabs {
    font-size: 1.2em;
  }

  .page-title-tabs {
    font-size: 1.2em;
  }

  .retro-console {
    font-size: 1em;
  }

  .retro-console th {
    font-size: 1em;
  }

  .retro-console th,
  .retro-console td{
    padding: var(--size-xs) var(--size-s);
  }

  .interaction-card {
    padding: var(--size-s) var(--size-m);
  }

  .game-card {
    font-size: 1.4em;
    flex-basis: 100%;
  }

  #announcement-layout {
    width: calc(100vw - var(--size-xl));
  }

  .announcement-card {
    padding: var(--size-m) var(--size-l);
  }

  .announcement-card__title {
    margin: var(--size-m) 0 60px;
    font-size: 80px;
  }

  .announcement-card__time {
    font-size: 52px;
    margin-bottom: 80px;
  }

  .announcement-card__moment {
    font-size: var(--font-l);
  }

  .announcement-card__description {
    font-size: var(--font-s);
    line-height: 1.2;
    margin-bottom: var(--size-m);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}